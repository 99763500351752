export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const PROFILE_RETRIEVE = "PROFILE_RETRIEVE";
export const PROFILE_UPDATE = "PROFILE_UPDATE";
export const LOGOUT = "LOGOUT";

export const ARTICLE_CREATE = "ARTICLE_CREATE";
export const ARTICLE_UPDATE = "ARTICLE_UPDATE";
export const ARTICLE_RETRIEVE = "ARTICLE_RETRIEVE";
export const ARTICLE_RETRIEVE_MORE = "ARTICLE_RETRIEVE_MORE";
export const ARTICLE_DELETE = "ARTICLE_DELETE";
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_POPULAR = "SET_POPULAR";
export const SET_NEWEST = "SET_NEWEST";

export const COMMENT_RETRIEVE = "COMMENT_RETRIEVE";
export const COMMENT_RETRIEVE_MORE = "COMMENT_RETRIEVE_MORE";
export const COMMENT_CREATE = "COMMENT_CREATE";
export const COMMENT_DELETE = "COMMENT_DELETE";
export const SET_CURRENT_COMMENT_PAGE = "SET_CURRENT_COMMENT_PAGE";

export const TAG_RETRIEVE = "TAG_RETRIEVE";
export const TAG_CREATE = "TAG_CREATE";
export const TAG_DELETE = "TAG_DELETE";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

