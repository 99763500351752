import React from "react";


const Home = () => {



  return (
    <div className="container">
      <header className="jumbotron">
        <h3>Home page</h3>
      </header>
    </div>
  );
};

export default Home;
